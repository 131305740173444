@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?c4d58640953c9cbd50c7919c8a9451bf?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?c4d58640953c9cbd50c7919c8a9451bf") format("woff2"),
url("./cy-custom-icons.woff?c4d58640953c9cbd50c7919c8a9451bf") format("woff"),
url("./cy-custom-icons.ttf?c4d58640953c9cbd50c7919c8a9451bf") format("truetype"),
url("./cy-custom-icons.svg?c4d58640953c9cbd50c7919c8a9451bf#cy-custom-icons") format("svg");
}

i.icon.cy {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.cy:before {
    content: "\f101";
}
